const AboutArray = [
    {
        class: "first",
        title: "Blockchain",
        end: "3"
    },
    {
        class: "second",
        title: "Listed Tokens",
        end: "1500"
    },
    {
        class: "third",
        title: "Downloads",
        end: "10000"
    },
    {
        class: "last",
        title: "Community",
        end: "157000"
    },
]
export default AboutArray;